import { useEffect, useMemo, useRef, useState, Fragment } from 'react'
import { round, unit, tan, equal, ceil, floor } from 'mathjs'
import cloneDeep from 'lodash.clonedeep'

import Step from './configuratorLists/Step'
import Length from './configuratorLists/Length'
import Installations from './configuratorLists/Installations'
import Preview from './configuratorLists/Preview'

import calculateConfiguratorPrice from './configuratorCalculatePrice'
import calculateConfiguratorPowerInfo from './configuratorCalculatePowerInfo'

// import data from './data.json'

/* Steps info:
name = title of step
type = type of step
slug = slug to search step info
listStep = index of steps array where to search for own slugs (e.g. search color's slug in led_strip (first selection so listStep = 0)))
localSlug (localSlug slug to check overrides) // TODO
optional = optional step (only if slug is present is present in required index)
requiredIndex = index of required product (e.g.: it becomes required if the first choise (index = 0) has slug (or is present in the products array). if the slug of the selected step of required index is set to false / null this step will be skipped)
requiredSlug = (overrides slug to search for in requiredIndex (only used to see if it should be enabled or not, slug is still used to look for data))

note: skipSlug IS NOT A PROPERTY, it's a fake name for a step such as strip, profile, ecc

E.G:

first step:
striscia A {
	name: 'aaaaa',
	skipSlug: false
}

select striscia A

step 2: {
	name 'bbbbb',
	slug: 'skipSlug',
	optional: true,
	requiredIndex: 0,
}

step 2 will be skipped.
if(striscia A had skipSlug: ['slug1', 'slug2'], or if it where undefined and there was a list of products called "skipSlug" it would've been activated)


localSlug: search for localSlug slug, fallback to slug if not present then fallback to general if not present (used in case slug of products in array of products is different from local slug)

				"installations": [
					{ "slug": "parete-interno-frontale", "priceCut": 1000 },
					{ "slug": "parete-interno-verticale", "priceCut": 1000 },
					{ "slug": "parete-interno-alto", "priceCut": 1000 },
					{ "slug": "parete-interno-basso", "priceCut": 1000 },
					{ "slug": "parete-esterno-frontale", "priceCut": 1000 },
					{ "slug": "parete-esterno-verticale", "priceCut": 1000 },
					{ "slug": "parete-esterno-alto", "priceCut": 1000 },
					{ "slug": "parete-esterno-basso", "priceCut": 1000 }
				],

*/

const stripSteps = [
	{ name: 'Striscia', type: 'select', slug: 'led_strips', collapsed: 0 },
	{ name: 'Temperatura colore', type: 'list', listStep: 0, slug: 'colors', collapsed: 0 },
	{ name: 'Cavo', type: 'list', slug: 'cable_lengths', listStep: 0, collapsed: 0 },
	{
		name: 'Cavo',
		type: 'list',
		listStep: 0,
		slug: 'cable_lengths',
		localSlug: 'cables',
		collapsed: 0,
		extra: [
			{
				slug: 'none',
				name: 'Nessuno',
				priceFixed: 0,
				default: true,
			},
		],
	},
]

const profileSteps = [
	{ name: 'Profilo', type: 'select', slug: 'profiles', collapsed: 0 },
	{ name: 'Finiture', type: 'list', listStep: 0, slug: 'finishings', local: 'finishings', collapsed: 0, extraIndexes: [0] },
	{ name: 'Coperture', type: 'list', listStep: 0, slug: 'covers', optional: true, local: 'covers', collapsed: 0, extraIndexes: [0], requiredIndex: 0 },
]
const all = [
	{ name: 'Striscia', type: 'select', slug: 'led_strips', collapsed: 0 },
	{ name: 'Temperatura colore', type: 'list', listStep: 0, slug: 'colors', collapsed: 0 },
	{ name: 'Profilo', type: 'select', slug: 'profiles', multiplierIndexes: [0, 4, 7], previewSpace: true, collapsed: 0 },
	{ name: 'Finiture', type: 'list', slug: 'finishings', local: 'finishings', listStep: 2, extraIndexes: [2], collapsed: 0 },
	{ name: 'Coperture', type: 'list', slug: 'covers', optional: true, local: 'covers', listStep: 2, extraIndexes: [2], requiredIndex: 2, collapsed: 0 },
	// { name: 'Posizione Cavo', type: 'list', slug: 'cable_position', optional: true, local: 'cable_position', collapsed: 0, listStep: 2, requiredIndex: 2 },
	{ name: 'Opzioni', type: 'list', slug: 'options', optional: true, listStep: 2, requiredIndex: 2, collapsed: 0 },
	{ name: 'Alimentatore', type: 'calculate', slug: 'power_supplies', requiredSlug: 'hasPowerSupply', optional: true, requiredIndex: 5, previewSpace: true },
	{ name: 'Trattamenti', type: 'list', slug: 'resins', optional: true, listStep: 2, requiredIndex: 2, collapsed: 0 },
	{ name: 'Temperatura colore di partenza', type: 'input', slug: 'resins_extra_color', optional: true, requiredIndex: 7, collapsed: 0 },
	{ name: 'Lunghezza cavo', type: 'list', slug: 'cable_lengths', localSlug: 'cables', listStep: 2, previewSpace: true, collapsed: 0 },
	{ name: 'Colore cavo', type: 'list', slug: 'cable_finishings', optional: true, listStep: 2, requiredIndex: 2, collapsed: 0 },
	{ name: 'Posizione cavo', type: 'list', slug: 'cable_positions', optional: true, listStep: 2, requiredIndex: 2, collapsed: 0 },
]

export default function Configurator({
	configMode = 'lamp',
	exportFunction = () => {},
	closeFunction,
	importInfo,
	attributeInfo,
	selectedPriceList,
	fixedLengths = null,
	compositionInfo = null,
}) {
	const [data, setData] = useState([])
	const [dataLoaded, setDataLoaded] = useState(false)
	// Constants used in app
	const constants = useRef({
		indexes: { profile: { lamp: 2, profile: 0 }, strip: { lamp: 0, profile: 0 }, stripColor: { lamp: 1 }, power_supply: { lamp: 6, profile: 0 } },
		steps: { lamp: all, strip: stripSteps, profile: profileSteps },
	})

	// steps info
	const [steps, setSteps] = useState(cloneDeep(constants.current.steps[configMode]))
	const [updateStep, setUpdateStep] = useState(Math.random())
	const [notes, setNotes] = useState('')

	// lengths
	const [lengths, setLengths] = useState([{ value: 1000, name: '100', unit: 'cm', multiplier: 0.1, type: 1, lenMeasurement: 'min' }])
	const [cutAngle, setCutAngle] = useState(90)
	const [installationMethod, setInstallationMethod] = useState(null)

	// prices
	const [price, setPrice] = useState(0)
	const [prices, setPrices] = useState([])

	// power info
	const [powerInfo, setPowerInfo] = useState()
	const [refreshPowerInfo, setRefreshPowerInfo] = useState(Math.random())

	// configurator settings
	const [advanced, setAdvanced] = useState(false)
	const [mode, setMode] = useState(configMode)

	// Import flags
	const [modeImported, setModeImported] = useState(false)
	const [dataImported, setDataImported] = useState(false)

	const [cutList, setCutList] = useState()

	useEffect(() => {
		fetch('/data/data.json')
			.then((res) => res.json())
			.then((data) => {
				setData(data)
				setDataLoaded(true)
			})

		if (fixedLengths !== null && Array.isArray(fixedLengths)) {
			setLengths(fixedLengths)
		}

		// eslint-disable-next-line
	}, [])

	// Calculate possible installations
	let installations = useMemo(() => {
		if (dataLoaded) {
			if (dataImported) {
				let profileIndex = constants.current.indexes.profile[mode]
				let installationsMethods = steps && steps[profileIndex] && steps[profileIndex].selected && steps[profileIndex].selected.installations

				if (!installationsMethods) {
					installationsMethods = data && data.products && data.products.installations
				} else {
					installationsMethods = Array.isArray(installationsMethods)
						? installationsMethods
								.map((installation) => {
									if (typeof installation === 'object') {
										// return installation

										let inst =
											data && data.products && Array.isArray(data.products.installations)
												? data.products.installations.find((installationOpt) => {
														if (installationOpt.slug === installation.slug) return true
														return false
												  })
												: null
										if (inst) {
											return { ...inst, profileCod: steps && steps[profileIndex].selected.cod, priceCut: installation.priceCut }
										}
									}
									if (typeof installation === 'string') {
										let inst =
											data && data.products && Array.isArray(data.products.installations)
												? data.products.installations.find((installationOpt) => {
														if (installationOpt.slug === installation) return true
														return false
												  })
												: null
										if (inst) {
											return inst
										}
									}
									return null
								})
								.filter(Boolean)
						: data && data.products && data.products.installations
				}
				return installationsMethods
			} else {
				return null
			}
		}
	}, [steps, mode, dataImported, dataLoaded, data])

	// effects

	// initial Load
	useEffect(() => {
		if (dataLoaded) {
			if (!importInfo) {
				setSteps(cloneDeep(constants.current.steps[mode]))
				setDataImported(true)
			} else {
				let importCopy = cloneDeep(importInfo)
				let modeImport = mode
				if (!modeImported && importCopy.mode && importCopy.mode !== mode) {
					modeImport = importCopy.mode
					setSteps(cloneDeep(constants.current.steps[modeImport]))
					setMode(modeImport)
					setModeImported(true)
				} else {
					// importCopy.steps.color
					if (importCopy.strip && importCopy.profile) {
						modeImport = 'lamp'
					} else if (importCopy.strip) {
						modeImport = 'strip'
					} else if (importCopy.profile) {
						modeImport = 'profile'
					}

					if (!modeImported && modeImport !== mode) {
						setSteps(cloneDeep(constants.current.steps[modeImport]))
						setMode(modeImport)
						setModeImported(true)
					} else {
						let hasStep = false
						let newSteps = cloneDeep(constants.current.steps[mode])
						let cableNumbers = 0

						if (Array.isArray(newSteps)) {
							newSteps = newSteps.map((step) => {
								switch (step.slug) {
									case 'led_strips':
										if (importCopy.strip && importCopy.strip.selected) {
											step.selected = importCopy.strip.selected
											hasStep = true
										}
										break
									case 'colors':
										if (importCopy.color && importCopy.color.selected) {
											step.selected = importCopy.color.selected
											hasStep = true
										}
										break
									case 'profiles':
										if (importCopy.profile && importCopy.profile.selected) {
											step.selected = importCopy.profile.selected
											hasStep = true
										}
										break
									case 'finishings':
										if (importCopy.finishing && importCopy.finishing.selected) {
											step.selected = importCopy.finishing.selected
											hasStep = true
										}
										break
									case 'covers':
										if (importCopy.cover && importCopy.cover.selected) {
											step.selected = importCopy.cover.selected
											hasStep = true
										}
										break
									case 'options':
										if (importCopy.option && importCopy.option.selected) {
											step.selected = importCopy.option.selected
											hasStep = true
										}
										break
									case 'power_supplies':
										if (importCopy.powerSupply && importCopy.powerSupply.selected) {
											step.selected = importCopy.powerSupply.selected
											hasStep = true
										}
										break
									case 'resins':
										if (importCopy.resin && importCopy.resin.selected) {
											step.selected = importCopy.resin.selected
											hasStep = true
										}
										break
									case 'cable_lengths':
										if ((importCopy.cable_1 && importCopy.cable_1.selected) || (importCopy.cable_2 && importCopy.cable_2.selected)) {
											cableNumbers++
										}
										if (cableNumbers === 2) {
											if (importCopy.cable_2 && importCopy.cable_2.selected) {
												step.selected = importCopy.cable_2.selected
											} else {
												if (importCopy.cable_1 && importCopy.cable_1.selected) {
													step.selected = importCopy.cable_1.selected
												}
											}
										}
										if (cableNumbers === 1) {
											if (importCopy.cable && importCopy.cable.selected) {
												step.selected = importCopy.cable.selected
												hasStep = true
											}
										}

										break
									default:
								}
								return step
							})
							if (hasStep) {
								setSteps(newSteps)
							}
						}

						if (importCopy.notes) setNotes(importCopy.notes)
						if (importCopy.lengths && !fixedLengths) setLengths(importCopy.lengths)
						if (importCopy.cutAngle) setCutAngle(importCopy.cutAngle)
						if (importCopy.advanced) setAdvanced(importCopy.advanced)
						if (importCopy.installationMethod) setInstallationMethod(importCopy.installationMethod)
						if (importCopy.powerInfo) setPowerInfo(importCopy.powerInfo)
						setDataImported(true)
					}
				}
			}
		}
		// eslint-disable-next-line
	}, [mode, dataLoaded])

	// eslint-disable-next-line
	useEffect(calculatePrice, [steps, lengths, installationMethod, advanced, dataImported, dataLoaded])
	// eslint-disable-next-line
	useEffect(calculatePowerInfo, [refreshPowerInfo, lengths, dataImported, dataLoaded])
	// eslint-disable-next-line
	useEffect(checkDefault, [updateStep, dataImported, dataLoaded])
	useEffect(calculateLengthMinMax, [lengths, steps, advanced, cutAngle, mode, installationMethod, dataImported, dataLoaded, data])

	// toggle advanced mode with multiple lengths
	useEffect(() => {
		if (dataLoaded) {
			if (dataImported) {
				const newLengths = [...lengths]
				if (advanced && newLengths.length <= 1) {
					newLengths.push({ value: 1000, name: '100', unit: 'cm', multiplier: 0.1, type: 1, lenMeasurement: 'min' })
				} else if (!advanced && newLengths.length > 1) {
					newLengths.pop()
				}

				setLengths(newLengths)
			}
		}
		// eslint-disable-next-line
	}, [advanced, dataLoaded])

	// Functions
	function calculateLengthMinMax() {
		if (dataLoaded) {
			if (dataImported) {
				let newLengths = [...lengths]
				let changed = false

				newLengths = newLengths.map((length) => {
					// calculate length min / max / mid measurement
					// if (advanced) {
					let calculatedMin = 0
					let calculatedMax = 0
					let calculatedMid = 0

					let alpha = advanced ? cutAngle / 2 : 90
					let base = 0
					// distanza dalla minima
					let preBase = null
					// distanza dalla massima
					let postBase = null

					let profileIndex = constants.current.indexes.profile[mode]
					let selected = steps && steps[profileIndex] && steps[profileIndex].selected
					if (selected) {
						if (installationMethod) {
							switch (installationMethod.measurement) {
								case 'width':
									base = selected.width
									if (typeof selected.preWidth === 'number') preBase = selected.preWidth
									if (typeof selected.postWidth === 'number') postBase = selected.postWidth
									break
								case 'height':
									base = selected.height
									if (typeof selected.preHeight === 'number') preBase = selected.preHeight
									if (typeof selected.postHeight === 'number') postBase = selected.postHeight
									break
								default:
									base = selected.width
									if (typeof selected.preWidth === 'number') preBase = selected.preWidth
									if (typeof selected.postWidth === 'number') postBase = selected.postWidth
							}

							if (installationMethod.invertInner === 2) {
								let temp = preBase
								preBase = postBase
								postBase = temp
							}
						} else {
							base = selected.width
						}

						// TODO: aggiustare misura data dall'utente in base alla tipologia di profillo)
					}

					if (typeof base !== 'number') base = 0

					let lengthValue = length.value
					if (lengthValue > 1000 * ((data && data.configs && data.configs.max_profile_length) || (mode === 'strip' ? 100 : 6)))
						lengthValue = mode === 'strip' ? 100000 : 6000

					let deltaLen = 0

					if (advanced) {
						switch (length.lenMeasurement) {
							// misura in input: punta minima
							case 'min':
								if (preBase !== null) {
									let innerDeltaLen = preBase * tan(unit(alpha, 'deg'))
									calculatedMin = lengthValue - innerDeltaLen
								} else {
									calculatedMin = lengthValue
								}

								deltaLen = base * tan(unit(alpha, 'deg'))
								calculatedMid = calculatedMin + deltaLen / 2
								calculatedMax = calculatedMin + deltaLen
								break
							// misura in input: punta media
							case 'mid':
								// if pre / post are present calculate min and max, than calculate mid using max
								if (preBase !== null || postBase !== null) {
									let innerDeltaLenMin = (preBase + (base - preBase - postBase) / 2) * tan(unit(alpha, 'deg'))
									calculatedMin = lengthValue - innerDeltaLenMin
									let innerDeltaLenMax = (postBase + (base - preBase - postBase) / 2) * tan(unit(alpha, 'deg'))
									calculatedMax = lengthValue + innerDeltaLenMax

									deltaLen = (base / 2) * tan(unit(alpha, 'deg'))
									calculatedMid = calculatedMax - deltaLen / 2
								} else {
									calculatedMid = lengthValue
									deltaLen = (base / 2) * tan(unit(alpha, 'deg'))
									calculatedMax = calculatedMid + deltaLen
									calculatedMin = calculatedMid - deltaLen
								}

								break
							// misura in input: punta massima
							case 'max':
								if (postBase !== null) {
									let innerDeltaLen = postBase * tan(unit(alpha, 'deg'))
									calculatedMax = lengthValue + innerDeltaLen
								} else {
									calculatedMax = lengthValue
								}
								deltaLen = base * tan(unit(alpha, 'deg'))
								calculatedMid = calculatedMax - deltaLen / 2
								calculatedMin = calculatedMax - deltaLen
								break
							// misura in input: punta minima
							default:
								if (preBase !== null) {
									let innerDeltaLen = preBase * tan(unit(alpha, 'deg'))
									calculatedMin = lengthValue - innerDeltaLen
								} else {
									calculatedMin = lengthValue
								}

								deltaLen = base * tan(unit(alpha, 'deg'))
								calculatedMid = calculatedMin + deltaLen / 2
								calculatedMax = calculatedMin + deltaLen
						}
					} else {
						calculatedMax = length.value
						calculatedMid = length.value
						calculatedMin = length.value
						// length.calculatedMax = calculatedMax
						// length.calculatedMid = calculatedMid
						// length.calculatedMin = calculatedMin
					}

					// Check if any length changed checking whether to update or not
					if (!equal(calculatedMax, length.calculatedMax)) {
						length.calculatedMax = calculatedMax
						changed = true
					}
					if (!equal(calculatedMid, length.calculatedMid)) {
						length.calculatedMid = calculatedMid
						changed = true
					}
					if (!equal(calculatedMin, length.calculatedMin)) {
						length.calculatedMin = calculatedMin
						changed = true
					}
					if (!equal(base, length.base)) {
						length.base = base
						changed = true
					}

					// console.log(length)
					// if !advanced there is no cut so all lengths are equal
					// } else {
					// 	if (length.calculatedMax !== undefined) {
					// 		delete length.calculatedMax
					// 		changed = true
					// 	}
					// 	if (length.calculatedMid !== undefined) {
					// 		delete length.calculatedMid
					// 		changed = true
					// 	}
					// 	if (length.calculatedMin !== undefined) {
					// 		delete length.calculatedMin
					// 		changed = true
					// 	}
					// }

					let calculatedMaxCutLen
					let calculatedMinCutLen

					// calculate cuts
					let stripIndex = constants.current.indexes.strip[mode]
					if (steps && steps[stripIndex] && steps[stripIndex].selected) {
						if (steps[stripIndex].selected.cut) {
							let calculatedLength = length.value / (steps[stripIndex].selected.cut / 10)
							calculatedMaxCutLen = (ceil(calculatedLength) * steps[stripIndex].selected.cut) / 10
							calculatedMinCutLen = (floor(calculatedLength) * steps[stripIndex].selected.cut) / 10
						}
					}

					if (!equal(calculatedMaxCutLen, length.maxCutLen)) {
						length.maxCutLen = calculatedMaxCutLen
						changed = true
					}
					if (!equal(calculatedMinCutLen, length.minCutLen)) {
						length.minCutLen = calculatedMinCutLen
						changed = true
					}

					return length
				})

				if (changed) {
					setLengths(newLengths)
				}
			}
		}
	}

	// calculate power supply used
	function findPowerSupply(dataList, power = powerInfo) {
		let selectIndex = -1
		let quantity = 0
		let hasVolt = false

		if (!power || power.volt === undefined || power.volt === null || !power.watt) {
			return { selectIndex: selectIndex, quantity: quantity }
		}

		while (selectIndex === -1) {
			quantity++
			for (let i = 0; i < dataList.length; i++) {
				if (dataList[i].volt === power.volt) {
					hasVolt = true
				}
				if (dataList[i].max_power * quantity >= power.watt && dataList[i].volt === power.volt) {
					selectIndex = i
					break
				}
			}

			if (!hasVolt) {
				break
			}
		}

		return { selectIndex: selectIndex, quantity: quantity }
	}

	function calculatePowerInfo() {
		if (dataLoaded) {
			if (dataImported) {
				if (Array.isArray(steps)) {
					// let newPowerInfo = { ...powerInfo }

					// // calculate power consumption
					// let volt = 0
					// let wattM = 0
					// let wattFix = 0
					// let watt = 0

					// steps.forEach((step) => {
					// 	// calculate led strip power consumption
					// 	if (step.slug === 'led_strips') {
					// 		if (step.selected) {
					// 			volt = step.selected.volt
					// 			wattFix += step.selected.power || 0.0
					// 			wattM += step.selected.power_m || 0.0

					// 			lengths.forEach((length) => {
					// 				watt += ceil(
					// 					(step.selected.power_m || 0.0) *
					// 						min(
					// 							// length rounded to ceil of 0.5
					// 							// chain(length.calculatedMax ? length.calculatedMax : length.value)
					// 							// 	.divide(500)
					// 							// 	.ceil()
					// 							// 	.divide(2)
					// 							// 	.done(),

					// 							divide(length.calculatedMax ? length.calculatedMax : length.value, 1000),
					// 							6
					// 						),
					// 					1
					// 				)
					// 				watt += step.selected.power || 0.0
					// 			})
					// 		}

					// 		// check profiles for multiplier (profiles with 2 led strips)
					// 	} else if (step.slug === 'profiles') {
					// 		if (step.selected && step.selected.multiplier) {
					// 			wattFix += step.selected.power || 0.0

					// 			if (Array.isArray(step.multiplierIndexes)) {
					// 				step.multiplierIndexes.forEach((index) => {
					// 					if (steps[index] && steps[index].selected) {
					// 						wattM += multiply(steps[index].selected.power_m || 0.0, step.selected.multiplier - 1)
					// 						lengths.forEach((length) => {
					// 							watt += ceil(
					// 								multiply(
					// 									(steps[index].selected.power_m || 0.0) *
					// 										min(
					// 											// length rounded to ceil of 0.5
					// 											// chain(length.calculatedMax ? length.calculatedMax : length.value)
					// 											// 	.divide(500)
					// 											// 	.ceil()
					// 											// 	.divide(2)
					// 											// 	.done(),

					// 											divide(length.calculatedMax ? length.calculatedMax : length.value, 1000),
					// 											6
					// 										),
					// 									step.selected.multiplier - 1
					// 								),
					// 								1
					// 							)
					// 							watt += step.selected.power || 0.0
					// 						})
					// 					}
					// 				})
					// 			}
					// 		}
					// 	} else if (step.slug !== 'power_supplies') {
					// 		if (step.selected) {
					// 			wattFix += step.selected.power || 0.0
					// 			wattM += step.selected.power_m || 0.0

					// 			lengths.forEach((length) => {
					// 				watt += ceil(
					// 					(step.selected.power_m || 0.0) *
					// 						min(
					// 							// length rounded to ceil of 0.5
					// 							// chain(length.calculatedMax ? length.calculatedMax : length.value)
					// 							// 	.divide(500)
					// 							// 	.ceil()
					// 							// 	.divide(2)
					// 							// 	.done(),

					// 							divide(length.calculatedMax ? length.calculatedMax : length.value, 1000),
					// 							6
					// 						),
					// 					1
					// 				)
					// 				watt += step.selected.power || 0.0
					// 			})
					// 		}
					// 	}
					// })
					// newPowerInfo.volt = volt
					// newPowerInfo.wattM = wattM
					// newPowerInfo.wattFix = wattFix
					// newPowerInfo.watt = watt

					let newPowerInfo = calculateConfiguratorPowerInfo({ steps, lengths, powerInfo, mode })

					let newSteps = [...steps]
					let stepIndex = newSteps.findIndex((step) => step.slug === 'power_supplies')

					// check power supplies
					if (stepIndex !== -1 && newSteps[stepIndex].active) {
						if (stepIndex !== -1) {
							let dataList = data && data.products && data.products.power_supplies

							// filter power supplies with usable un lamp
							dataList = dataList.filter((ps) => ps.useInLamps)

							if (Array.isArray(dataList)) {
								dataList.sort((a, b) => {
									return a.max_power - b.max_power
								})

								let { selectIndex, quantity } = findPowerSupply(dataList, newPowerInfo)

								if (selectIndex !== -1) {
									newSteps[stepIndex].selected = dataList[selectIndex]
									newSteps[stepIndex].quantity = quantity
									setSteps(newSteps)
								} else {
									delete newSteps[stepIndex].selected
									newSteps[stepIndex].quantity = 0
									setSteps(newSteps)
								}
							}
						}

						let optionsIndex = newSteps[stepIndex].requiredIndex
						if (optionsIndex || optionsIndex === 0) {
							newSteps[optionsIndex].quantity = newSteps[stepIndex].quantity
						}
					}

					setPowerInfo(newPowerInfo)
				}
			}
		}
	}

	// function calcPriceToAdd(step, key) {
	// 	let toAdd = step.selected[key]
	// 	if (typeof toAdd !== 'number') toAdd = 0

	// 	if (typeof step.quantity === 'number' && step.quantity > 0) toAdd *= step.quantity

	// 	// Avoid paying double for power supplies (paying power supplies for each lenght)
	// 	if (step.slug === 'power_supplies' && advanced) toAdd = divide(toAdd, 2)

	// 	// Prezzo di 1 centrallina per ogni alimentatore, (diviso per i segmenti)
	// 	// let powerSupply = steps[constants.current.indexes.power_supply[mode]]
	// 	// if (step.slug === 'options' && typeof (powerSupply && powerSupply.quantity) === 'number') {
	// 	// 	toAdd = divide(multiply(toAdd, powerSupply.quantity), advanced ? 2 : 1)
	// 	// } else
	// 	if (step.slug === 'options' && advanced) {
	// 		toAdd = divide(toAdd, 2)
	// 	}

	// 	return toAdd
	// }

	function calculatePrice() {
		if (dataLoaded) {
			if (dataImported) {
				// let priceMeter = 0
				// let priceFixed = 0
				// let priceWork = 0
				// let priceAccessories = 0

				// // let length = 1

				// // calculate option prices (fixed costs + cost per meter)
				// if (Array.isArray(steps)) {
				// 	steps.forEach((step) => {
				// 		if (step.selected) {
				// 			// Price meter
				// 			let toAdd = calcPriceToAdd(step, 'price')
				// 			priceMeter = chain(priceMeter).add(toAdd).done()

				// 			// Price fixed
				// 			toAdd = calcPriceToAdd(step, 'priceFixed')
				// 			priceFixed = chain(priceFixed).add(toAdd).done()

				// 			// Price work
				// 			toAdd = calcPriceToAdd(step, 'work')
				// 			priceWork = chain(priceWork).add(toAdd).done()

				// 			// Price Accessories
				// 			toAdd = calcPriceToAdd(step, 'priceAccessories')
				// 			priceAccessories = chain(priceAccessories).add(toAdd).done()

				// 			// check for multipliers
				// 			if (Array.isArray(step.multiplierIndexes)) {
				// 				if (typeof step.selected.multiplier === 'number') {
				// 					step.multiplierIndexes.forEach((index) => {
				// 						// Price meter
				// 						if (steps[index].selected) {
				// 							let toAdd = calcPriceToAdd(steps[index], 'price')
				// 							let multiplyPrice = chain(toAdd)
				// 								.multiply(step.selected.multiplier - 1)
				// 								.done()
				// 							priceMeter = chain(priceMeter).add(multiplyPrice).done()

				// 							// Price fixed
				// 							toAdd = calcPriceToAdd(steps[index], 'priceFixed')
				// 							multiplyPrice = chain(toAdd)
				// 								.multiply(step.selected.multiplier - 1)
				// 								.done()
				// 							priceFixed = chain(priceFixed).add(multiplyPrice).done()

				// 							// Price work
				// 							toAdd = calcPriceToAdd(steps[index], 'work')
				// 							multiplyPrice = chain(toAdd)
				// 								.multiply(step.selected.multiplier - 1)
				// 								.done()
				// 							priceWork = chain(priceWork).add(multiplyPrice).done()

				// 							// Price Accessories
				// 							toAdd = calcPriceToAdd(steps[index], 'priceAccessories')
				// 							multiplyPrice = chain(toAdd)
				// 								.multiply(step.selected.multiplier - 1)
				// 								.done()
				// 							priceAccessories = chain(priceAccessories).add(multiplyPrice).done()
				// 						}
				// 					})
				// 				} else if (typeof step.selected.priceMultiplier === 'number') {
				// 					step.multiplierIndexes.forEach((index) => {
				// 						// Price meter
				// 						if (steps[index].selected) {
				// 							let toAdd = calcPriceToAdd(steps[index], 'price')
				// 							let multiplyPrice = chain(toAdd)
				// 								.multiply(step.selected.priceMultiplier - 1)
				// 								.done()
				// 							priceMeter = chain(priceMeter).add(multiplyPrice).done()
				// 						}
				// 					})
				// 				}
				// 			}

				// 			if (Array.isArray(step.extraIndexes)) {
				// 				if (typeof step.selected.extra === 'number') {
				// 					step.extraIndexes.forEach((index) => {
				// 						// Price meter
				// 						if (steps[index].selected) {
				// 							let toAdd = calcPriceToAdd(steps[index], 'price')
				// 							let additionalPrice = chain(toAdd).multiply(step.selected.extra).subtract(toAdd).done()
				// 							priceMeter = chain(priceMeter).add(additionalPrice).done()

				// 							let toAddWork = calcPriceToAdd(steps[index], 'work')
				// 							let additionalPriceWork = chain(toAddWork).multiply(step.selected.extra).subtract(toAddWork).done()
				// 							priceWork = chain(priceWork).add(additionalPriceWork).done()
				// 						}
				// 					})
				// 				}
				// 			}
				// 		}
				// 	})
				// }

				// if (installationMethod && advanced) {
				// 	let priceCut = installationMethod.priceCut
				// 	if (typeof priceCut !== 'number') priceCut = 0
				// 	priceFixed = chain(priceFixed).add(priceCut).done()
				// }

				// // calculate total price based on length / lengths
				// let newPrices = [...prices]
				// let totalPrice = -1
				// lengths.forEach((length, i) => {
				// 	let workCost = multiply(priceWork, (data && data.configs && data.configs.markup) || 1)
				// 	let roundedLength = chain(typeof length.calculatedMax === 'number' ? length.calculatedMax : length.value)
				// 		.divide(500)
				// 		.ceil()
				// 		.divide(2)
				// 		.done()
				// 	if (roundedLength <= 0) {
				// 		roundedLength = 0.5
				// 	}
				// 	if (roundedLength > (mode === 'strip' ? 100000 : 5)) {
				// 		roundedLength = 6
				// 	}

				// 	let lengthExtra = 0
				// 	if (mode !== 'strip') {
				// 		if (roundedLength > ((data && data.configs && data.configs.extra_length_thresold) || 3)) {
				// 			// add extra misura
				// 			lengthExtra = chain(lengthExtra)
				// 				.add((data && data.configs && data.configs.extra_length) || 25)
				// 				.done()
				// 		}
				// 	}

				// 	if (mode === 'profile') {
				// 		workCost = 0
				// 	}

				// 	let accessoriesPrice = chain(ceil(roundedLength + 1))
				// 		.multiply(priceAccessories)
				// 		.done()

				// 	let lengthPrice = chain(roundedLength).multiply(priceMeter).add(priceFixed).add(lengthExtra).add(workCost).add(accessoriesPrice).done()

				// 	if (mode !== 'strip') {
				// 		if (roundedLength > 1) {
				// 			// add extra misura
				// 			lengthPrice = chain(lengthPrice)
				// 				.multiply(pow((data && data.configs && data.configs.meter_length_surplus) || 1.03, roundedLength - 1))
				// 				.done()
				// 		}
				// 	}

				// 	// Calculate extra pricing based on priceList
				// 	if (selectedPriceList && data && data.configs && data.configs.priceModifiers) {
				// 		if (data.configs.priceModifiers[selectedPriceList.pricelist_id]) {
				// 			// apply price modifier
				// 			let mult = data.configs.priceModifiers[selectedPriceList.pricelist_id].multiplier
				// 			let priceModifier = parseFloat(mult)
				// 			if (!isNaN(priceModifier)) {
				// 				lengthPrice = lengthPrice * priceModifier
				// 			}
				// 		}
				// 	}

				// 	let rounded = round(lengthPrice, 2)

				// 	if (newPrices[i] === undefined) {
				// 		newPrices.push(rounded)
				// 	} else {
				// 		newPrices[i] = rounded
				// 	}

				// 	totalPrice += lengthPrice
				// })

				// if (totalPrice === -1) {
				// 	totalPrice = 0
				// } else {
				// 	totalPrice += 1
				// }

				// let rounded = round(totalPrice, 2)

				let [rounded, newPrices] = calculateConfiguratorPrice({
					steps,
					installationMethod,
					advanced,
					lengths,
					prices,
					selectedPriceList,
					data,
					mode,
					compositionInfo,
				})

				setPrice(rounded)
				setPrices(newPrices)
			}
		}
	}

	// check steps for default (if no step is selected select the default option)
	function checkDefault() {
		if (dataLoaded) {
			if (dataImported) {
				let newSteps = []
				if (Array.isArray(steps)) {
					newSteps = [...steps]
					newSteps.forEach((step, i) => {
						if (step && (!step.selected || step.slug === 'resins_extra_color')) {
							let list = []

							// get data for step
							switch (step.type) {
								case 'select':
									list = [...(step.extra || []), ...((data && data.products && data.products[step.slug]) || [])]
									break
								case 'list':
									const selectedStep = steps && steps[step.listStep] && steps[step.listStep].selected
									if (selectedStep) {
										let slugsList = selectedStep[step.localSlug] ? selectedStep[step.localSlug] : selectedStep[step.slug]
										// fallback to select items
										if (!slugsList) {
											slugsList = [...(step.extra || []), ...((data && data.products && data.products[step.slug]) || [])]
										} else {
											slugsList = [...(step.extra || []), ...(slugsList || [])]
										}

										const productsList = data && data.products && data.products[step.slug]
										list =
											Array.isArray(slugsList) &&
											slugsList.map((slug) => {
												if (typeof slug === 'string') {
													if (Array.isArray(productsList)) {
														const returnValue = productsList.find((product) => product.slug === slug)
														if (returnValue) return returnValue
														return {}
													}
													return {}
												} else if (typeof slug === 'object') {
													return slug
												}
												return {}
											})
									}
									break
								case 'input':
									// console.log(step)
									if (step.slug === 'resins_extra_color' && step.active && (!step.selected || !step.selected.isChanged)) {
										// console.log(
										// 	steps[step.requiredIndex]?.selected?.resin_color_modifier,
										// 	steps[constants.current.indexes.stripColor[mode] + 1]?.selected
										// )
										if (
											steps[constants.current.indexes.stripColor[mode]]?.selected &&
											steps[constants.current.indexes.strip[mode]]?.selected
										) {
											let offset = steps[step.requiredIndex]?.selected?.resin_color_modifier
												? steps[step.requiredIndex].selected.resin_color_modifier
												: 0
											let selectedColor = steps[constants.current.indexes.stripColor[mode]].selected.slug
											let resinFromColor = steps[constants.current.indexes.stripColor[mode]].selected.resin_from_color
											let colors = steps[constants.current.indexes.strip[mode]].selected.colors
											if (resinFromColor) {
												if (colors.find((color) => parseInt(color) === parseInt(resinFromColor))) {
													let currentColor = parseInt(resinFromColor)
													let extraColor = currentColor + 'K'

													if (currentColor + offset !== parseInt(selectedColor)) {
														extraColor = `Colore di partenza: ${currentColor}K, finale: ${currentColor + offset}K`
													}

													newSteps[i].selected = { value: extraColor, name: extraColor }
												} else {
													let minColor
													let maxColor

													let [colorUp, colorDown, indexUp, indexDown] = colors.reduce(
														([colorUp, colorDown, indexUp, indexDown], color, i) => {
															// console.log(
															// 	color,
															// 	colorUp,
															// 	resinFromColor,
															// 	parseInt(color) >= parseInt(resinFromColor) && parseInt(color) < colorUp
															// )
															if (parseInt(color) >= parseInt(resinFromColor) && parseInt(color) < colorUp) {
																colorUp = parseInt(color)
																indexUp = i
															}
															if (parseInt(color) <= parseInt(resinFromColor) && parseInt(color) > colorDown) {
																colorDown = parseInt(color)
																indexDown = i
															}

															return [colorUp, colorDown, indexUp, indexDown]
														},
														[999999999, 0, -1, -1]
													)

													if (indexUp !== -1) {
														maxColor = data.products.colors.find((color) => parseInt(color.slug) === parseInt(colors[indexUp]))
													}
													if (indexDown !== -1) {
														minColor = data.products.colors.find((color) => parseInt(color.slug) === parseInt(colors[indexDown]))
													}

													let hasBoth = maxColor && minColor && maxColor !== minColor
													let hasNone = !maxColor && !minColor

													let finalColor
													if (hasNone) {
														finalColor = data.products.colors.find(
															(color) => parseInt(color.resin_from_color) === parseInt(selectedColor) - offset
														)
													}

													let usedColor = ''
													if (hasNone) {
														if (finalColor) {
															usedColor = `\nVerra usato il colore: partenza ${finalColor.slug}K, finale ${
																parseInt(finalColor.slug) + offset
															}K`
														} else {
															usedColor = `\nVerra usato il colore: partenza ${selectedColor}K, finale ${
																parseInt(steps[constants.current.indexes.stripColor[mode]].selected.slug) + offset
															}K`
														}
													} else if (hasBoth) {
														let desiredColor = parseInt(steps[constants.current.indexes.stripColor[mode]]?.selected.slug)
														if (
															Math.abs(parseInt(minColor.slug) - desiredColor) < Math.abs(parseInt(maxColor.slug) - desiredColor)
														) {
															usedColor = `\nVerra usato il colore: partenza ${minColor.slug}K, finale ${
																parseInt(minColor.slug) + offset
															}K`
														} else {
															usedColor = `\nVerra usato il colore: partenza ${maxColor.slug}K, finale ${
																parseInt(maxColor.slug) + offset
															}K`
														}

														// usedColor = `\nSpecificare uno fra questi 2 colori: partenza ${minColor.slug}K, finale ${
														// 	parseInt(minColor.slug) + offset
														// }K oppure: partenza ${maxColor.slug}K, finale ${parseInt(maxColor.slug) + offset}K`
													} else {
														usedColor = `\nVerra usato il colore: partenza ${minColor ? minColor.slug : maxColor.slug}K, finale ${
															parseInt(minColor ? minColor.slug : maxColor.slug) + offset
														}K`
													}

													// console.log(minColor, maxColor, colorDown, colorUp, finalColor, offset)

													newSteps[i].selected = {
														value: `ATTENZIONE: non è possibile ottenere il colore ${
															steps[constants.current.indexes.stripColor[mode]]?.selected.name
														}.${usedColor}`,
														name: `ATTENZIONE: non è possibile ottenere il colore ${
															steps[constants.current.indexes.stripColor[mode]]?.selected.name
														}.${usedColor}`,
													}
												}
											} else {
												newSteps[i].selected = { value: 'nessuna variazione colore', name: 'nessuna variazione colore' }
											}
											// let currentColor = parseInt(steps[constants.current.indexes.stripColor[mode]].selected.slug)
											// currentColor += parseInt(steps[step.requiredIndex].selected.resin_color_modifier)
											// newSteps[i].selected = { value: currentColor + 'K', name: currentColor + 'K' }
										}
									}
									break
								default:
							}
							// check data for default option
							if (Array.isArray(list)) {
								let defaultselected = false
								list.forEach((item) => {
									if (item.default && !defaultselected) {
										defaultselected = true
										newSteps[i].selected = item
										// newSteps[i].value = item.name
									}
								})
								if (step.slug !== 'profiles' && step.slug !== 'led_strips' && step.slug !== 'colors' && !defaultselected && list.length > 0) {
									newSteps[i].selected = list[0]
									// newSteps[i].value = list[0].name
								}
							}
						}
					})
				}

				const { newSteps: newStepsOptional, changed } = checkOptionals(newSteps)
				newSteps = newStepsOptional

				// check if installation is not set or not valid
				if (
					Array.isArray(installations) &&
					(!installationMethod ||
						!installations.find((inst) => installationMethod.slug === inst.slug && installationMethod.profileCod === inst.profileCod))
				) {
					let defaultSetted = false
					installations.forEach((installation) => {
						if (installation.default) {
							defaultSetted = true
							setInstallationMethod({ ...installation })
						}
					})
					if (!defaultSetted && installations.length > 0) {
						setInstallationMethod({ ...installations[0] })
					}
				}

				if (changed) {
					setUpdateStep(Math.random())
				}

				setSteps(newSteps)
				setRefreshPowerInfo(Math.random())
			}
		}
	}

	// check for optionals steps
	function checkOptionals(baseSteps = steps) {
		let newSteps = [...baseSteps]
		let changed = false

		newSteps.forEach((step, i) => {
			// Check for optional steps
			if (step.optional) {
				if (
					newSteps[step.requiredIndex] &&
					newSteps[step.requiredIndex].selected &&
					newSteps[step.requiredIndex].selected[step.requiredSlug || step.slug] !== undefined
				) {
					if (
						newSteps[step.requiredIndex].selected[step.requiredSlug || step.slug] !== false &&
						newSteps[step.requiredIndex].selected[step.requiredSlug || step.slug] !== null
					) {
						if (!step.active) {
							changed = true
							step.active = true
						}
					} else {
						if (step.active) {
							changed = true
							step.active = false
							delete step.selected
						}
					}
				} else {
					if (step.active) {
						changed = true
						step.active = false
						delete step.selected
					}
				}
			}

			// Check if already selected options are valid
			if (step.listStep !== undefined) {
				const returnData = checkValid({ stepIndex: i, stepsArray: newSteps })
				newSteps = returnData[0]
				if (!changed) changed = returnData[1]
			}
		})

		return { newSteps: newSteps, changed: changed }
	}

	// check if selected options are valid, recursively
	function checkValid({ stepIndex, stepsArray = steps, root = true }) {
		let returnValue = false
		let changed = null
		if (stepsArray && stepsArray[stepIndex]) {
			if (stepsArray[stepIndex].selected) {
				let listStep = stepsArray[stepIndex].listStep
				let slug = stepsArray[stepIndex].slug
				let localSlug = stepsArray[stepIndex].localSlug

				if (listStep !== undefined) {
					if (stepsArray[listStep] && stepsArray[listStep].selected) {
						let selected = stepsArray[listStep].selected
						let slugsList = []
						if (selected[localSlug || slug]) {
							slugsList = [...(stepsArray[stepIndex].extra || []), ...(selected[localSlug || slug] || [])]
						} else {
							slugsList = [...(stepsArray[stepIndex].extra || []), ...((data && data.products && data.products[slug]) || [])]
						}

						const productsList = data && data.products && data.products[slug]
						let list =
							Array.isArray(slugsList) &&
							slugsList.map((localSlug) => {
								if (typeof localSlug === 'string') {
									if (Array.isArray(productsList)) {
										const returnProduct = productsList.find((product) => product.slug === localSlug)
										if (returnProduct) return returnProduct
										return {}
									}
									return {}
								} else if (typeof localSlug === 'object') {
									return localSlug
								}
								return {}
							})

						if (Array.isArray(list)) {
							for (let i = 0; i < list.length; i++) {
								if (list[i].slug === stepsArray[stepIndex].selected.slug) returnValue = true
							}
						}
					}
				}
			} else {
				returnValue = true
			}
		}
		if (!returnValue) {
			delete stepsArray[stepIndex].selected
		}
		let returnArray = [...stepsArray]
		stepsArray.forEach((step, i) => {
			if (i !== stepIndex && step.listStep === stepIndex) {
				const returnData = checkValid({ stepIndex: i, stepsArray: stepsArray, root: false })
				returnArray = returnData[0]
				changed = returnData[1]
			}
		})

		return [returnArray, !returnValue || changed === false]
	}

	// Select an item
	function stepSelect(selected, value, stepIndex) {
		let newSteps = [...steps]
		newSteps[stepIndex].selected = selected
		// newSteps[stepIndex].value = value

		let newIndex = stepIndex + 1
		for (; newIndex < newSteps.length; newIndex++) {
			if (!newSteps[newIndex].optional || newSteps[newIndex].active) break
		}

		setSteps(newSteps)
		// setCurrentStep(newIndex)
		setUpdateStep(Math.random())
		setRefreshPowerInfo(Math.random())
	}

	function checkout() {
		const errors = []

		if (Array.isArray(lengths)) {
			lengths.forEach((length, i) => {
				if (isNaN(length.value) || (typeof length.value !== 'number' && typeof length.value !== 'string')) {
					// Lunghezza non valida
					errors.push({ message: 'Lunghezza inserita non valida, inserire un valore maggiore di 0 e minore di 6000mm' })
				} else {
					if (length.value <= 0) {
						// Lunghezza troppo inferiore o uguale a 0
						errors.push({
							message: `Lunghezza inserita troppo piccola, inserire un valore maggiore di 0 e minore di ${mode === 'strip' ? 100000 : 6000}mm`,
						})
					} else if (length.value > (mode === 'strip' ? 100000 : 6000)) {
						// Lunghezza superiore a 6m (o 100m per strisce)
						errors.push({
							message: `Lunghezza inserita troppo grande, inserire un valore maggiore di 0 e minore di ${mode === 'strip' ? 100000 : 6000}mm`,
						})
					} else if (round(length.calculatedMax || length.value, 2) > (mode === 'strip' ? 100000 : 6000)) {
						// Lunghezza superiore a 6m (o 100m per strisce)
						errors.push({
							message: `Lunghezza Massima del taglio troppo grande, inserire un valore maggiore di 0 e minore di ${
								mode === 'strip' ? 100000 : 6000
							}mm`,
						})
					}
				}

				if (advanced) {
					if (!installationMethod) {
						// metodo di installazione mancante
						errors.push({ message: 'Metodo di installazione mancante' })
					}
					if (typeof length.calculatedMin !== 'number' || typeof length.calculatedMax !== 'number' || typeof length.calculatedMid !== 'number') {
						// errore nel calcolare la cosa di taglio
						errors.push({ message: 'Errore nel calcolare lunghezze per il taglio' })
					}
					if (cutAngle < 45 || cutAngle >= 180) {
						// angolo di taglio non valido
						errors.push({ message: 'Angolo di taglio non valido, inserire un valore compreso tra 45 e 180' })
					}
				}
			})
		} else {
		}

		if (Array.isArray(steps)) {
			steps.forEach((step) => {
				if (!step.optional || step.active) {
					if (!step.selected) {
						// manca step
						errors.push({ message: `Nessuna opzione "${step.name}" selezionata` })
					}
				}
			})
		}

		return errors
	}

	function cutIcon(label) {
		switch (label) {
			case 'up':
				return (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M13 7.828V20h-2V7.828l-5.364 5.364-1.414-1.414L12 4l7.778 7.778-1.414 1.414L13 7.828z" />
					</svg>
				)
			case 'down':
				return (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M13 16.172l5.364-5.364 1.414 1.414L12 20l-7.778-7.778 1.414-1.414L11 16.172V4h2v12.172z" />
					</svg>
				)
			case 'right':
				return (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" />
					</svg>
				)
			case 'left':
				return (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z" />
					</svg>
				)
			default:
				return 'no Cut'
		}
	}

	return (
		<div className="configurator-page">
			<header className="configurator-header">
				<h1 className="configurator-header-title">Configura il tuo prodotto</h1>
			</header>
			<div className="configurator-main">
				<div className="configurator-current-step">
					<div className={`measurements-info ${advanced ? 'advanced' : ''}`}>
						{advanced && (
							<Installations
								installations={installations}
								installationMethod={installationMethod}
								setInstallationMethod={setInstallationMethod}
							/>
						)}
						{fixedLengths === null &&
							lengths.map((length, i) => {
								return (
									<Fragment key={i}>
										{i === 1 && (
											<div className="length-swap-icon">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													onClick={() => {
														let newLengths = [...lengths]
														let temp = cloneDeep(newLengths[0])
														newLengths[0] = cloneDeep(newLengths[1])
														newLengths[1] = temp
														setLengths(newLengths)
													}}
												>
													<path fill="none" d="M0 0h24v24H0z" />
													<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-5-7h9v2h-4v3l-5-5zm5-4V6l5 5H8V9h4z" />
												</svg>
											</div>
										)}
										<Length
											mode={mode}
											length={length}
											index={i}
											lengths={lengths}
											setLengths={setLengths}
											steps={steps}
											stripIndex={0}
											advanced={advanced}
											data={data}
											cutAngle={cutAngle}
											setCutAngle={setCutAngle}
										/>
									</Fragment>
								)
							})}
						{advanced && (
							<div className="cut-input">
								<div className="configurator-input-box">
									<label className="configurator-input-label" htmlFor="cutAngle">
										Angolo di taglio
									</label>
									<input
										className="configurator-input"
										id="cutAngle"
										value={cutAngle}
										onInput={(e) => {
											if (/^[+-]?[0-9]*[.,]?[0-9]*$/gimu.test(e.target.value)) {
												if (e.target.value < 180 && e.target.value >= 45) {
												}
												setCutAngle(e.target.value.replace(',', '.'))
											}
										}}
									/>
								</div>
								<div className="cut-input-circle">
									<div className="cut-input-fixed">
										{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36">
											<path fill="none" d="M0 0h24v24H0z" />
											<path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm0 18c4.42 0 8-3.58 8-8s-3.58-8-8-8-8 3.58-8 8 3.58 8 8 8zm3.536-12.95l1.414 1.414-4.95 4.95L10.586 12l4.95-4.95z" />
										</svg> */}
									</div>
									<div className="cut-input-variable" style={{ '--cut-angle': `-${cutAngle}deg` }}></div>
								</div>
							</div>
						)}
						<div className={`cut-toggle ${advanced ? 'advanced' : ''}`}>
							<label className="configurator-toggle-label" htmlFor="advanced">
								Taglio
							</label>
							<div className="configurator-toggle-container">
								<div className="configurator-toggle-off">Off</div>
								<div
									className={`configurator-toggle ${advanced ? 'checked' : ''}`}
									id="advanced"
									checked={advanced}
									onClick={() => {
										setAdvanced(!advanced)
									}}
								>
									<div className="configurator-toggle-index"></div>
								</div>
								<div className="configurator-toggle-on">On</div>
							</div>
						</div>
					</div>
					{Array.isArray(steps) &&
						steps.map((step, i) => {
							if (step.optional && !step.active) return null
							return (
								<Step
									key={i}
									steps={steps}
									setSteps={setSteps}
									stepIndex={i}
									stepSelect={stepSelect}
									data={data}
									selectedPriceList={selectedPriceList}
									attributeInfo={attributeInfo}
								/>
							)
						})}
					<div className="step">
						<h1 className="step-title">
							Note
							{false && <div style={{ fontSize: '28px' }}>{/* <FontAwesomeIcon icon={faAngleDoubleDown} /> */}</div>}
						</h1>
						<div className={`step-content-container`}>
							<textarea
								className={`step-notes`}
								placeholder="Inserire qui eventuali note..."
								value={notes}
								onInput={(e) => setNotes(e.target.value)}
							/>
						</div>
					</div>
					{/* {steps[constants.current?.indexes?.profile[mode]].selected?.hasPowerSupply !== true && (
						<div className="step">
							<h1 className="step-title">
								Alimentatore
								{false && <div style={{ fontSize: '28px' }}></div>}
							</h1>
							<div className={`step-content-container`}></div>
						</div>
					)} */}

					{cutList && (
						<div className={`cut-list-grid ${cutList.cut ? '' : 'compact'}`}>
							<div className="cut-list-cell">Qty</div>
							<div className="cut-list-cell">Modello</div>
							<div className="cut-list-cell">Finitura</div>
							<div className="cut-list-cell cut-list-drawing-area">
								<div>
									<div>Lunghezza</div>
									<div>Spessore</div>
									<div>Angolo</div>
								</div>
								{cutList.cut && (
									<>
										<div>
											<div>Disegno1</div>
										</div>
										<div>
											<div>Disegno direzione1</div>
										</div>
										<div></div>
										<div>
											<div>Disegno2</div>
										</div>
										<div>
											<div>Disegno direzione1</div>
										</div>
									</>
								)}
							</div>
							<div className="cut-list-cell">---</div>
							<div className="cut-list-cell">---</div>
							<div className="cut-list-cell">---</div>
							<div className="cut-list-cell cut-list-drawing-area">
								<div>---</div>
								{cutList.cut && (
									<>
										<div>---</div>
										<div>---</div>
									</>
								)}
							</div>
							<>
								<div className="cut-list-cell">{cutList.cut ? '2' : '1'}</div>
								<div className="cut-list-cell">{cutList.profile && cutList.profile.selected && cutList.profile.selected.name}</div>
								<div className="cut-list-cell">{cutList.finishing && cutList.finishing.selected && cutList.finishing.selected.name}</div>
								<div className="cut-list-cell cut-list-drawing-area">
									<div>
										{Array.isArray(cutList.lengths) &&
											cutList.lengths.map((length, i) => {
												if (i !== 0) return null
												return (
													<Fragment key={i}>
														<div>
															Lung.:&nbsp;
															{length.cutInfo
																? typeof length[length.cutInfo.key] === 'number'
																	? round(length[length.cutInfo.key], 2)
																	: length.value * 10
																: `${length.value * 10}`}
															mm
														</div>
														<div>Spessore:&nbsp;{length.base}mm</div>
														{cutList.cut && <div>Angolo:&nbsp;{cutAngle / 2 + (length.cutInfo ? length.cutInfo.angle : 0)}°</div>}
													</Fragment>
												)
											})}
									</div>
									{cutList.cut && (
										<>
											<div>
												{/* <div>Disegno1</div> */}
												<div style={{ transform: 'rotateX(180deg)' }}>
													<img src={`./images/taglio-profilo.png`} alt="Taglio profilo" width="90%" />
												</div>
											</div>
											<div>
												<div>{cutList && Array.isArray(cutList.cutPositions) && cutIcon(cutList.cutPositions[0])}</div>
												{/* <div>Disegno direzione 2</div> */}
											</div>{' '}
										</>
									)}
									{cutList.cut && (
										<>
											<div>
												{Array.isArray(cutList.lengths) &&
													cutList.lengths.map((length, i) => {
														if (i !== 1) return null
														return (
															<Fragment key={i}>
																<div>
																	Lung.:&nbsp;
																	{length.cutInfo
																		? typeof length[length.cutInfo.key_B] === 'number'
																			? round(length[length.cutInfo.key_B], 2)
																			: length.value * 10
																		: `${length.value * 10}`}
																	mm
																</div>
																<div>Spessore:&nbsp;{length.base}mm</div>
																<div>Angolo:&nbsp; {cutAngle / 2 + (length.cutInfo ? length.cutInfo.angle_B : 0)}°</div>
															</Fragment>
														)
													})}
											</div>
											<div>
												<div style={{ transform: 'rotateY(180deg)' }}>
													<img src={`./images/taglio-profilo.png`} alt="Taglio profilo" width="90%" />
												</div>
											</div>
											<div>
												<div>{cutList && Array.isArray(cutList.cutPositions) && cutIcon(cutList.cutPositions[1])}</div>
												{/* <div>Disegno direzione 2</div> */}
											</div>
										</>
									)}
								</div>
							</>
						</div>
					)}
				</div>
				<aside className="configurator-side-preview">
					<Preview
						lengths={lengths}
						advanced={advanced}
						cutAngle={cutAngle}
						installationMethod={installationMethod}
						steps={steps}
						powerInfo={powerInfo}
						price={price}
						attributeInfo={attributeInfo}
					/>
					<div
						className="configurator-checkout-button"
						onClick={() => {
							const errors = checkout()

							if (Array.isArray(errors) && errors.length > 0) {
								let messaggio = 'Sono presenti degli errori: \n'
								errors.forEach((error) => {
									messaggio += `  • ${error.message} \n`
								})
								alert(messaggio)
							} else {
								exportFunction(
									cloneDeep({
										steps: steps,
										lengths: lengths,
										installationMethod: installationMethod,
										cutAngle: cutAngle,
										advanced: advanced,
										powerInfo: powerInfo,
										notes: notes,
										price: price,
										mode: mode,
										version: 2,
									})
								)
							}
						}}
					>
						{importInfo ? 'Aggiorna' : 'Aggiungi'}
					</div>
					{/* {typeof closeFunction === 'function' && (
						<div
							className="configurator-checkout-button"
							onClick={() => {
								closeFunction()
							}}
						>
							Chiudi
						</div>
					)} */}
					<div
						className="configurator-checkout-button"
						onClick={() => {
							let strip = null
							let color = null
							let profile = null
							let finishing = null
							let cover = null
							let option = null
							let powerSupply = null
							let resins = null
							let cable = null
							let cable_1 = null
							let cable_2 = null
							let cableFinishing = null
							let cablePosition = null

							steps.forEach((step) => {
								switch (step.slug) {
									case 'led_strips':
										strip = step
										break

									case 'colors':
										color = step
										break

									case 'profiles':
										profile = step
										break

									case 'finishings':
										finishing = step
										break
									case 'covers':
										if (step.active) {
											cover = step
										}
										break
									case 'options':
										if (step.active) {
											option = step
										}
										break
									case 'power_supplies':
										if (step.active) {
											powerSupply = step
										}
										break

									case 'resins':
										if (step.active) {
											resins = step
										}
										break

									case 'cables':
									case 'cable_lengths':
										if (cable_1) {
											cable_2 = step
											cable = null
										} else {
											cable_1 = cable
											cable = step
										}

										break
									case 'cable_colors':
										if (step.active) {
											cableFinishing = step
										}
										break

									case 'cable_positions':
										if (step.active) {
											cablePosition = step
										}
										break
									default:
								}
							})

							let map = {
								1: {
									right: {
										key: 'calculatedMax',
										key_B: 'calculatedMin',
										angle: 90,
										angle_B: 0,
									},
									left: { key: 'calculatedMin', key_B: 'calculatedMax', angle: 0, angle_B: 90 },
								},
								2: {
									right: {
										key: 'calculatedMin',
										key_B: 'calculatedMax',
										angle: 0,
										angle_B: 90,
									},
									left: { key: 'calculatedMax', key_B: 'calculatedMin', angle: 90, angle_B: 0 },
								},
								3: {
									up: {
										key: 'calculatedMin',
										key_B: 'calculatedMax',
										angle: 0,
										angle_B: 90,
									},
									down: { key: 'calculatedMax', key_B: 'calculatedMin', angle: 90, angle_B: 0 },
								},
								4: {
									up: {
										key: 'calculatedMax',
										key_B: 'calculatedMin',
										angle: 90,
										angle_B: 0,
									},
									down: { key: 'calculatedMin', key_B: 'calculatedMax', angle: 0, angle_B: 90 },
								},
								5: {
									up: {
										key: 'calculatedMin',
										key_B: 'calculatedMax',
										angle: 0,
										angle_B: 90,
									},
									down: { key: 'calculatedMax', key_B: 'calculatedMin', angle: 90, angle_B: 0 },
								},
								6: {
									up: {
										key: 'calculatedMax',
										key_B: 'calculatedMin',
										angle: 90,
										angle_B: 0,
									},
									down: { key: 'calculatedMin', key_B: 'calculatedMax', angle: 0, angle_B: 90 },
								},
							}

							let cutPositions = []
							let lengthInfo = lengths.map((length, i) => {
								if (installationMethod) {
									if (map[installationMethod.installationType] && map[installationMethod.installationType].down) {
										let cutOptions = profile && profile.selected && profile.selected.cutOptions
										if (Array.isArray(cutOptions)) {
											if (cutOptions.includes('down')) {
												length.cutInfo = map[installationMethod.installationType].down
												cutPositions.push('down')
											} else {
												length.cutInfo = map[installationMethod.installationType].up
												cutPositions.push('up')
											}
										} else {
											length.cutInfo = map[installationMethod.installationType].down
											cutPositions.push('down')
											// length.cutInfo2 = map[installationMethod.installationType][1]
										}
										length.cutDirections = cutOptions
									}
									if (map[installationMethod.installationType] && map[installationMethod.installationType].right) {
										let cutOptions = profile && profile.selected && profile.selected.cutOptions
										if (Array.isArray(cutOptions)) {
											if (cutOptions.includes('right')) {
												length.cutInfo = map[installationMethod.installationType].right
												cutPositions.push('right')
											} else {
												length.cutInfo = map[installationMethod.installationType].left
												cutPositions.push('left')
											}
										} else {
											length.cutInfo = map[installationMethod.installationType].right
											cutPositions.push('right')
											// length.cutInfo2 = map[installationMethod.installationType][4]
										}
										length.cutDirections = cutOptions
									}
								}

								return length
							})

							if (installationMethod) {
								if (installationMethod.invertLengths === 2) {
									let newLengths = [...lengthInfo]
									let temp = cloneDeep(newLengths[0])
									newLengths[0] = cloneDeep(newLengths[1])
									newLengths[1] = temp
									lengthInfo = newLengths
								}
							}

							let productInfo = {
								type: 'customLamp',
								strip: strip,
								color: color,
								profile: profile,
								finishing: finishing,
								cover: cover,
								option: option,
								powerSupply: powerSupply,
								resings: resins,
								cable: cable,
								cable_1: cable_1,
								cable_2: cable_2,
								cableFinishing,
								cablePosition,
								lengths: lengthInfo,
								installation: installationMethod,
								cutAngle: cutAngle,
								cutPositions: cutPositions,
								cut: advanced,
								notes: notes,
							}

							setCutList({ ...productInfo })
						}}
					>
						Calculate Cut List
					</div>
				</aside>
			</div>
		</div>
	)
}
